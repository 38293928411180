import { useDisclosure } from '@/hooks/use-disclosure'
import { css, useTheme } from '@emotion/react'
import useWebAnimations from '@wellyshen/use-web-animations'
import { Link } from 'gatsby'
import React, { ButtonHTMLAttributes, HTMLAttributes, ReactNode } from 'react'
import { Transition } from 'react-transition-group'
import { styles } from '@/styles/styles'
import { Collapse } from './collapse'
// @ts-ignore
import background from '@/images/background-01.svg'

const links = [
  {
    to: '/news-list',
    target: undefined,
    rel: undefined,
    label: '新着情報',
    hoverColor: 'magenta',
    lang: 'ja',
    submenu: null,
  },
  {
    to: '/introduction/ja',
    target: undefined,
    rel: undefined,
    label: 'プロジェクト紹介',
    hoverColor: 'yellow',
    lang: 'ja',
    submenu: [
      {
        to: '/introduction/ja#schedule',
        label: 'スケジュール',
      },
      {
        to: '/introduction/ja#stages',
        label: '3つのステージ',
      },
    ],
  },
  {
    to: '/messages',
    target: undefined,
    rel: undefined,
    label: '高校生へのメッセージ',
    hoverColor: 'orange',
    lang: 'ja',
    submenu: null,
  },
  {
    to: '/videos',
    target: undefined,
    rel: undefined,
    label: '写真&ビデオ',
    hoverColor: 'green',
    lang: 'ja',
    submenu: null,
  },
  {
    to: '/introduction/en',
    target: undefined,
    rel: undefined,
    label: 'Information in English',
    hoverColor: 'blue',
    lang: 'en',
    submenu: null,
  },
  {
    to: 'https://jp.surveymonkey.com/r/6DPXMirai',
    target: '_blank',
    rel: 'noopener noreferrer',
    label: '質問・お問合せ',
    hoverColor: 'primary',
    lang: 'ja',
    submenu: null,
  },
] as const

export interface NaviProps extends HTMLAttributes<HTMLElement> {
  isTopPage?: boolean
  isScrolled?: boolean
}

export function Navi({ isTopPage, isScrolled, ...props }: NaviProps) {
  const theme = useTheme()
  const menu = links.slice(0, links.length - 1)
  const contact = links.slice(links.length - 1)[0]

  return (
    <nav {...props}>
      <div css={styles.textRight}>
        <Link
          to={contact.to}
          target={contact.target}
          rel={contact.rel}
          css={[
            styles.flexCenter,
            css`
              display: inline-flex;
              width: 20rem;
              height: 4rem;
              border: solid 1px;
              border-radius: 9999px;
              font-size: ${theme.fontSizes.body4};
              letter-spacing: ${theme.letterSpacings.tight};
              transition: 0.25s ease-out;
              transition-property: color, background-color, border-color;

              &:hover {
                color: ${theme.colors.white};
                background-color: ${theme.colors.primary.main};
                border-color: transparent;
              }
            `,
          ]}
        >
          <span
            className="icon-mail"
            css={css`
              margin-right: 0.8rem;
            `}
          />
          {contact.label}
        </Link>
      </div>

      <ul
        css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          margin-top: 2rem;
          font-size: ${theme.fontSizes.body3};
          letter-spacing: ${theme.letterSpacings.wide};

          & > li:not(:first-of-type) {
            margin-left: 4rem;
          }
        `}
      >
        {menu.map(({ to, target, rel, label, hoverColor, lang }, index) => (
          <li key={index}>
            <Link
              to={to}
              target={target}
              rel={rel}
              css={[
                css`
                  transition: color 0.25s ease-out;
                `,
                isTopPage
                  ? css`
                      &:hover {
                        color: ${theme.colors.gray.light};
                      }
                    `
                  : css`
                      &:hover {
                        color: ${hoverColor === 'orange'
                          ? theme.colors.orange.dark
                          : theme.colors[hoverColor].main};
                      }
                    `,
                lang === 'en' &&
                  css`
                    font-family: ${theme.fonts.roboto};
                    letter-spacing: ${theme.letterSpacings.tight};
                  `,
              ]}
            >
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

interface DrawerNaviProps extends NaviProps {}

const overlayStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
} as const

const drawerStyles = {
  entering: { right: 0 },
  entered: { right: 0 },
  exiting: { right: '-100%' },
  exited: { right: '-100%' },
  unmounted: { right: '-100%' },
} as const

const animationOptions = {
  duration: 300,
  fill: 'both',
} as const

export function DrawerNavi({
  isTopPage,
  isScrolled,
  ...props
}: DrawerNaviProps) {
  const theme = useTheme()
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false })
  const { ref: drawerRef, animate: animateDrawer } =
    useWebAnimations<HTMLDivElement>({
      autoPlay: false,
    })
  const { ref: overlayRef, animate: aniamteOverlay } =
    useWebAnimations<HTMLDivElement>({
      autoPlay: false,
    })

  return (
    <nav {...props}>
      <DrawerMenuButton
        onClick={onOpen}
        css={css`
          position: relative;
          top: 3rem;
          right: 1rem;
          color: ${isTopPage && !isScrolled
            ? theme.colors.white
            : theme.colors.black};
        `}
      />

      <Transition in={isOpen} timeout={animationOptions.duration} unmountOnExit>
        {state => {
          if (state === 'entering' || state === 'exiting') {
            animateDrawer({
              keyframes: drawerStyles[state],
              animationOptions,
            })

            aniamteOverlay({
              keyframes: overlayStyles[state],
              animationOptions,
            })
          }

          return (
            <div
              css={css`
                position: fixed;
                top: 0;
                left: 0;
                z-index: 100;
                width: 100vw;
                height: 100vh;
              `}
              onClick={onClose}
              ref={overlayRef}
            >
              <div
                css={css`
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-color: ${theme.colors.primary.light};
                  opacity: 0.45;
                `}
              />

              <div
                onClick={e => {
                  e.stopPropagation()
                }}
                css={css`
                  position: absolute;
                  top: 0;
                  right: -100%;
                  z-index: 1;
                  width: 100%;
                  max-width: 38.8rem;
                  height: 100vh;
                  padding: 7.6rem 3.2rem 0;
                  background: ${theme.colors.gray.lighter} url(${background})
                    center / 192rem;
                `}
                ref={drawerRef}
              >
                <ul>
                  {links.map(
                    ({ label, to, target, rel, lang, submenu }, index) => (
                      <li
                        key={index}
                        css={css`
                          border-bottom: solid 1px;
                          padding: 3.6rem 0;
                        `}
                      >
                        {submenu ? (
                          <NaviAccordion summary={label}>
                            <ul
                              css={css`
                                padding-top: 2rem;

                                & > li:not(:first-of-type) {
                                  margin-top: 1rem;
                                }
                              `}
                            >
                              {submenu.map((item, index) => (
                                <li key={index}>
                                  <Link
                                    to={item.to}
                                    onClick={onClose}
                                    css={css`
                                      display: block;
                                      font-weight: ${theme.fontWeights.medium};
                                    `}
                                  >
                                    - {item.label}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </NaviAccordion>
                        ) : (
                          <Link
                            to={to}
                            target={target}
                            rel={rel}
                            onClick={onClose}
                            css={[
                              css`
                                display: block;
                              `,
                              lang === 'en' &&
                                css`
                                  font-family: ${theme.fonts.roboto};
                                  letter-spacing: ${theme.letterSpacings.tight};
                                `,
                            ]}
                          >
                            {label}
                          </Link>
                        )}
                      </li>
                    )
                  )}
                </ul>

                <CloseButton
                  onClick={onClose}
                  css={css`
                    position: absolute;
                    top: 4rem;
                    right: 3rem;
                  `}
                />
              </div>
            </div>
          )
        }}
      </Transition>
    </nav>
  )
}

interface DrawerMenuButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {}

function DrawerMenuButton(props: DrawerMenuButtonProps) {
  return (
    <button
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 6.2rem;
        height: 4.2rem;

        & > span {
          display: block;
          width: 100%;
          height: 2px;
          background-color: currentColor;
        }
      `}
      {...props}
    >
      <span />
      <span />
      <span />
    </button>
  )
}

interface CloseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

function CloseButton(props: CloseButtonProps) {
  const theme = useTheme()

  return (
    <button
      css={css`
        width: 4.5rem;

        span {
          display: block;
          width: 100%;
          height: 2px;
          cursor: pointer;
          background-color: currentColor;
        }
      `}
      {...props}
    >
      <span
        css={css`
          transform: rotate(45deg);
        `}
      />
      <span
        css={css`
          transform: rotate(-45deg) translate(1px, -1px);
        `}
      />
    </button>
  )
}

interface NaviAccordionProps extends HTMLAttributes<HTMLDivElement> {
  summary?: ReactNode
}

function NaviAccordion({ children, summary, ...props }: NaviAccordionProps) {
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: false,
  })

  return (
    <div {...props}>
      <button
        onClick={() => {
          onToggle()
        }}
        css={css`
          position: relative;
          display: block;
          width: 100%;
          text-align: left;
        `}
      >
        {summary}

        <span
          css={css`
            position: absolute;
            top: 50%;
            right: 0;
            width: 2rem;
            height: 2rem;
            transform: translateY(-50%);
          `}
        >
          <span
            css={css`
              position: absolute;
              top: 50%;
              left: 0;
              width: 100%;
              height: 2px;
              transform: translateY(-50%);
              background-color: currentColor;
            `}
          />
          <span
            css={[
              css`
                position: absolute;
                top: 0;
                left: 50%;
                width: 2px;
                height: 100%;
                transform: translateX(-50%);
                background-color: currentColor;
                transition: transform 0.3s;
              `,
              isOpen &&
                css`
                  transform: translateX(-50%) scale(0);
                `,
            ]}
          />
        </span>
      </button>

      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  )
}
